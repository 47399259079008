@use "node_modules/animate.css/animate.min.css";
@use "node_modules/ngx-toastr/toastr";

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  body {
    font-family: 'Roboto', sans-serif;
    background: url("/assets/body-background.jpeg") no-repeat center;
    //background: url("/assets/Banner.png") no-repeat center;
    background-size: cover;
    overflow: hidden;
  }

  button {
    @apply px-8 py-4 rounded text-white flex items-center justify-between uppercase font-bold tracking-wider;
  }

  input {
    &:focus {
      @apply outline-none;
    }
  }
}


.bg-glass {
  @apply bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-60;
}


@layer utilities {
  .text-white {
    @apply text-gray-300;
  }
}

.toast {
  top: 30px;
}
